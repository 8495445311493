<template>
  <div class="" v-if="partnerInfo">
    <div class="pagenamPT">
      <h3 class="pagename2">{{ $t('front.common.memberDetail') }}</h3>
    </div>
    <ul class="topInfo">
      <li class="bgA">
        <span>{{ $t('front.board.type') }}</span>
        <span>{{ partnerInfo.partnerLevelName }}</span>
      </li>
      <li class="bgB">
        <span>{{ $t('front.common.connectID') }}</span>
        <span>{{ partnerInfo.memId }}</span>
      </li>
      <li class="bgA">
        <span>{{ $t('front.common.nickName') }}</span>
        <span>{{ partnerInfo.memNick }}</span>
      </li>
      <li class="bgB">
        <span>{{ $t('front.cash.nav3') }}</span>
        <span>{{ thousand(partnerInfo.cashBalance || 0) }}</span>
      </li>
      <li class="bgC">
        <span>{{ $t('front.common.haveRolling') }}</span>
        <span>{{ thousand(partnerInfo.pointBalance || 0) }}</span>
      </li>
    </ul>

    <div class="tab-wrap">
      <a @click="onChangeType('partnerInfo')" class="tab-item" :class="{'active': type === 'partnerInfo'}">{{ $t('front.common.memberInfo') }}</a>
      <a @click="onChangeType('partnerRate')" class="tab-item" :class="{'active': type === 'partnerRate'}">{{ $t('front.common.rateInfo') }}</a>
      <a @click="onChangeType('partnerLog')" class="tab-item" :class="{'active': type === 'partnerLog'}">{{ $t('front.common.recentLogin') }}</a>
      <a @click="onChangeType('partnerMsg')" class="tab-item" :class="{'active': type === 'partnerMsg'}">{{ $t('front.common.note') }}</a>
      <a @click="onChangeType('partnerCash')" class="tab-item" :class="{'active': type === 'partnerCash'}">{{ $t('front.cash.changesInMoney') }}</a>
      <!--a @click="onChangeType('userCharge')" class="tab-item" :class="{'active': type === 'userCharge'}">충전받은 내역</a>
      <a @click="onChangeType('userExchange')" class="tab-item" :class="{'active': type === 'userExchange'}">환전나간 내역</a-->
      <a @click="onChangeType('userGame')" class="tab-item" :class="{'active': type === 'userGame'}">{{ $t('front.common.gameHistory') }}</a>
      <a @click="onChangeType('userBet')" class="tab-item" :class="{'active': type === 'userBet'}">{{ $t('front.common.gameBetHistory') }}</a>
    </div>

    <div class="tab-content">
      <div v-if="type == 'partnerInfo'">
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="info">
              <colgroup>
                <col width="20%">
                <col width="30%">
                <col width="20%">
                <col width="30%">
              </colgroup>
              <thead>
              <tr>
                <th colspan="4">
                  <div class="tTitle">
                      <span>
                        {{ $t('front.common.memberDetailInfo') }}
                        <em>{{ partnerInfo.memId }}</em>
                        (<i>{{ partnerInfo.memNick }}</i>)
                      </span>
                    <a class="btn-save" @click="saveDetail">{{ $t('front.stributor.save') }}</a>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ $t('front.common.memId') }}</td>
                <td>{{ partnerInfo.memId }}</td>
                <td>{{ $t('front.common.nickName') }}</td>
                <td><input type="text" v-model="partnerInfo.memNick" /></td>
              </tr>
              <tr>
                <td>{{ $t('front.cash.nav3') }}</td>
                <td>{{ thousand(partnerInfo.cashBalance || 0) }}</td>
                <td>{{ $t('front.cash.nav4') }}</td>
                <td>{{ thousand(partnerInfo.pointBalance || 0) }}</td>
              </tr>
              <tr>
                <td>{{ $t('front.common.loginFunc') }}</td>
                <td><input type="checkbox" class="set-switch" v-model="isLoginYn" @click="onChange('loginYn')"/></td>
                <td>{{ $t('front.common.joinDay') }}</td>
                <td>{{ partnerInfo.joinDate }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <ul>
                <li class="nodata">
                  <em>회원 상세 정보</em>
                  <div class="tTitle">
                    <span>
                      <em>{{ partnerInfo.memId }}</em>
                      (<i>{{ partnerInfo.memNick }}</i>)
                    </span>
                    <a class="btn-save" @click="saveDetail">저장</a>
                  </div>
                </li>
              </ul>
              <ul>
                <li><em>아이디</em>{{ partnerInfo.memId }}</li>
                <li><em>닉네임</em><input type="text" v-model="partnerInfo.memNick" /></li>
                <li><em>보유머니</em>{{ thousand(partnerInfo.cashBalance || 0) }}</li>
                <li><em>보유포인트</em>{{ thousand(partnerInfo.pointBalance || 0) }}</li>
                <li><em>로그인 가능</em><input type="checkbox" class="set-switch" v-model="isLoginYn" @click="onChange('loginYn')"/></li>
                <li><em>가입일</em><span class="txt">{{ partnerInfo.joinDate }}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == 'partnerRate'">
        <div class="partnerTop start">
          <a class="btn-save" @click="onSaveRate">{{ $t('front.stributor.save') }}</a>
        </div>
        <div class="strTablescr">
          <div>
            <table class="info">
              <colgroup>
                <col width="20%">
                <col width="40%">
                <col width="40%">
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t('front.stributor.roll') }}</th>
                  <th>{{ $t('front.stributor.lose') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="memRollingInfo">
                  <template v-for="rate in memRollingInfo" :key="rate.cateCode">
                    <template v-if="rate.detailSetYn === 'N'">
                      <tr>
                        <td>{{ rate.cateCodeName }}</td>
                        <td>
                          <div class="rateInput">
                            <button type="button" class="min" @click="rate.pointRate = rate.minPointRate">{{ $t('front.common.minimum') }}<i>({{rate.minPointRate}})</i></button>
                            <input type="number" v-model="rate.pointRate" @change="onChangeRate(rate.pointRate, rate.maxPointRate, rate.minPointRate, rate, 'point')">
                            <button type="button" class="max" @click="rate.pointRate = rate.maxPointRate">{{ $t('front.common.maximum') }}<i>({{rate.maxPointRate}})</i></button>
                          </div>
                        </td>
                        <td>
                          <div class="rateInput">
                            <button type="button" class="min" @click="rate.loseRate = rate.minLoseRate">{{ $t('front.common.minimum') }}<i>({{rate.minLoseRate}})</i></button>
                            <input type="number" v-model="rate.loseRate" @change="onChangeRate(rate.loseRate, rate.maxLoseRate, rate.minLoseRate, rate, 'lose')">
                            <button type="button" class="max" @click="rate.loseRate = rate.maxLoseRate">{{ $t('front.common.maximum') }}<i>({{rate.maxLoseRate}})</i></button>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td>{{ rate.cateCodeName }}</td>
                        <td colspan="3">
                          <div class="rateInput">
                            <button type="button" @click="onOpenRateDetail(rate.cateCode)">요율세부설정</button>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="multipleRateInfo" class="rateDetail">
          <a class="close" @click="multipleRateInfo = null"><img src="@/assets/img/icon_cancelB.svg" /></a>
          <table>
            <template v-for="rate in multipleRateInfo['rateList']" :key="rate.rateType">
              <tr>
                <td rowspan="2" class="bg">{{ rate.rateTypeName }}</td>
                <td>롤링</td>
                <td>
                  <div class="rateInput">
                    <button type="button" class="min" @click="rate.pointRate = rate.minPointRate">최소<i>( {{rate.minPointRate}} )</i></button>
                    <input type="number" v-model="rate.pointRate" @change="onChangeRate(rate.pointRate, rate.maxPointRate, rate.minPointRate, rate, 'point')">
                    <button type="button" class="max" @click="rate.pointRate = rate.maxPointRate">최대<i>( {{rate.maxPointRate}} )</i></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>루징</td>
                <td>
                  <div class="rateInput">
                    <button type="button" class="min" @click="rate.loseRate = rate.minLoseRate">최소<i>( {{rate.minLoseRate}} )</i></button>
                    <input type="number" v-model="rate.loseRate" @change="onChangeRate(rate.loseRate, rate.maxLoseRate, rate.minLoseRate, rate, 'lose')">
                    <button type="button" class="max" @click="rate.loseRate = rate.maxLoseRate">최대<i>( {{rate.maxLoseRate}} )</i></button>
                  </div>
                </td>
              </tr>
            </template>
          </table>
          <div class="rateInput">
            <button type="button" class="save" @click="onSaveMultipleRate">저장</button>
          </div>
        </div>
      </div>
      <div v-if="type == 'partnerLog'">
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="log">
              <colgroup>
                <col width="8%">
                <col width="40%">
                <col width="12%">
                <col width="25%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('front.common.connectIP') }}</th>
                  <th>{{ $t('front.common.successOrfail') }}</th>
                  <th>{{ $t('front.common.connection') }}</th>
                  <th>{{ $t('front.common.block') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loginLog && loginLog.length > 0">
                  <template v-for="(log, idx) in loginLog" :key="log.accessDate">
                    <tr>
                      <td>{{idx + 1}}</td>
                      <td>{{ log.accessIp }}</td>
                      <td> - </td>
                      <td>{{ log.accessDate.replace('T', ' ') }}</td>
                      <td><a class="btn-save block">차단하기</a></td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="5">{{ $t('front.common.loginHistory') }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="loginLog && loginLog.length > 0">
                <template v-for="(log, idx) in loginLog" :key="log.accessDate">
                  <ul>
                    <li><em>#</em>{{idx + 1}}</li>
                    <li><em>접속 IP</em>{{ log.accessIp }}</li>
                    <li><em>성공여부</em> - </li>
                    <li><em>접속일시</em>{{ log.accessDate.replace('T', ' ') }}</li>
                    <li><em>차단</em><a class="btn-save block">차단하기</a></li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{ $t('front.common.loginHistory') }}</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == 'partnerMsg'">
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="msg">
              <colgroup>
                <col width="10%">
                <col width="65%">
                <col width="10%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('front.search.content') }}</th>
                  <th>{{ $t('front.common.read') }}</th>
                  <th>{{ $t('front.board.regDate') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="msgLog && msgLog.length > 0">
                  <template v-for="msg in msgLog" :key="msg.msgIdx">
                    <tr>
                      <td>{{ msg.msgIdx }}</td>
                      <td><a @click="msg.isOpen = !msg.isOpen">{{ msg.msgTitle }}</a></td>
                      <td>{{ msg.msgStatus === 'READ' ? 'O' : 'X'}}</td>
                      <td>{{ msg.sendDate.replace('T', ' ')}}</td>
                    </tr>
                    <tr class="open" v-if="msg.isOpen">
                      <td colspan="5">
                          <pre class="pre">
                            {{msg.msgContents}}
                          </pre>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr><td colspan="4">{{ $t('front.common.noNote') }}</td></tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="msgLog && msgLog.length > 0">
                <template v-for="msg in msgLog" :key="msg.msgIdx">
                  <ul>
                    <li><em>#</em>{{ msg.msgIdx }}</li>
                    <li><em>내용</em><a @click="msg.isOpen = !msg.isOpen">{{ msg.msgTitle }}</a></li>
                    <li><em>읽음</em>{{ msg.msgStatus === 'READ' ? 'O' : 'X'}}</li>
                    <li><em>등록일시</em>{{ msg.sendDate.replace('T', ' ')}}</li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{ $t('front.common.noNote') }}</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
        <!--div class="btn-wrap">
          <a class="btn-add">신규 쪽지 작성</a>
        </div-->
        <pagination v-if="msgLog"
                    :pageNum="msgLogPageInfo.page"
                    :pageSize="msgLogPageInfo.count_per_list"
                    :totalCount="msgLogPageInfo.tatal_list_count"
                    @goToPage="loadMsgLog"
        />
      </div>
      <div v-if="type == 'partnerCash'">
        <div class="partnerTop">
          <select v-model="reqData.cashLog.cashType">
            <option value="">전체</option>
            <option value="1">{{ $t('front.common.deposit') }}</option>
            <option value="-1">{{ $t('front.common.withdrawal') }}</option>
            <option value="2">관리자 지급</option>
            <option value="-2">관리자 회수</option>
            <option value="-3">보유금 지급</option>
            <option value="3">보유금 회수</option>
            <option value="33">상부 지급</option>
            <option value="-33">상부 회수</option>
            <option value="4">포인트 전환</option>
          </select>
          <date-filter-Pt :startDate="reqData.cashLog.startDate"
                          :endDate="reqData.cashLog.endDate"
                          @update="updateCashLogDate"
                          @search="loadCashLog" />
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="msg">
              <colgroup>
                <col width="4%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
                <col width="12%">
              </colgroup>
              <thead>
              <tr>
                <th>#</th>
                <th>{{ $t('front.common.before') }}</th>
                <th>{{ $t('front.common.after') }}</th>
                <th>{{ $t('front.common.increDecre') }}</th>
                <th>{{ $t('front.common.sent') }}</th>
                <th>{{ $t('front.common.receive') }}</th>
                <th>{{ $t('front.common.change') }}</th>
                <th>{{ $t('front.common.reference') }}</th>
                <th>{{ $t('front.common.lastChange') }}</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="cashLog && cashLog.length > 0">
                <template v-for="log in cashLog" :key="log.cashIdx">
                  <tr>
                    <td>{{ log.cashIdx }}</td>
                    <td>{{ thousand(log.preCashAmt || 0) }}</td>
                    <td>{{ thousand(log.afterCashAmt || 0) }}</td>
                    <td>{{ thousand(log.cashAmt || 0) }}</td>
                    <td>{{ log.sender }}</td>
                    <td>{{ log.reciver }}</td>
                    <td>{{ CASH_TYPE_LIST(log.cashType) }}</td>
                    <td>{{ log.cashDesc }}</td>
                    <td>{{ log.updDate.replace('T', ' ') }}</td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr><td colspan="9">{{ $t('front.common.noChange') }}</td></tr>
              </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="cashLog && cashLog.length > 0">
                <template v-for="log in cashLog" :key="log.cashIdx">
                  <ul>
                    <li><em>#</em>{{ log.cashIdx }}</li>
                    <li><em>변동 전</em>{{ thousand(log.preCashAmt || 0) }}</li>
                    <li><em>변동 후</em>{{ thousand(log.afterCashAmt || 0) }}</li>
                    <li><em>증감</em>{{ thousand(log.cashAmt || 0) }}</li>
                    <li><em>보낸곳</em>{{ log.sender }}</li>
                    <li><em>받는곳</em>{{ log.reciver }}</li>
                    <li><em>변동사유</em>{{ CASH_TYPE_LIST(log.cashType) }}</li>
                    <li><em>참고값</em>{{ log.cashDesc }}</li>
                    <li><em>최종변동일시</em>{{ log.updDate.replace('T', ' ') }}</li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{ $t('front.common.noChange') }}</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
        <pagination v-if="cashLog"
                    :pageNum="cashLogPageInfo.page"
                    :pageSize="cashLogPageInfo.count_per_list"
                    :totalCount="cashLogPageInfo.tatal_list_count"
                    @goToPage="loadCashLog"
        />
      </div>
      <div v-if="type == 'userCharge'">
        <div class="partnerTop">
          <date-filter-Pt />
        </div>
        <table class="msg">
          <colgroup>
            <col width="10%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="15%">
            <col width="15%">
          </colgroup>
          <thead>
            <tr>
              <th>#</th>
              <th>충전 전</th>
              <th>충전 금액</th>
              <th>충전 후</th>
              <th>신청일시</th>
              <th>처리일시</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>0</td>
              <td>2,000,000</td>
              <td>2,000,000</td>
              <td>2024-07-16 17:51:51</td>
              <td>2024-07-16 17:51:51</td>
            </tr>
            <tr class="total">
              <td colspan="4">합계</td>
              <td colspan="2">10,000</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="type == 'userExchange'">
        <div class="partnerTop">
          <date-filter-Pt />
        </div>
        <table class="msg">
          <colgroup>
            <col width="10%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="15%">
            <col width="15%">
          </colgroup>
          <thead>
            <tr>
              <th>#</th>
              <th>환전 전</th>
              <th>환전 금액</th>
              <th>환전 후</th>
              <th>신청일시</th>
              <th>처리일시</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>0</td>
              <td>2,000,000</td>
              <td>2,000,000</td>
              <td>2024-07-16 17:51:51</td>
              <td>2024-07-16 17:51:51</td>
            </tr>
            <tr class="total">
              <td colspan="4">합계</td>
              <td colspan="2">10,000</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="type == 'userGame'">
        <div class="partnerTop">
          <date-filter-Pt :startDate="reqData.playGameLog.startDate"
                          :endDate="reqData.playGameLog.endDate"
                          @update="updatePlayGameLogDate"
                          @search="loadPlayGameLog" />
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="msg">
              <colgroup>
                <col width="10%">
                <col width="20%">
                <col width="55%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('front.common.supplier') }}</th>
                  <th>{{ $t('front.common.gameName') }}</th>
                  <th>{{ $t('front.common.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="playGameLog && playGameLog.length > 0">
                  <tr v-for="(game, index) in playGameLog" :key="game.regDate">
                    <td>{{ index + 1 }}</td>
                    <td>{{ game.vendorName }}</td>
                    <td>{{ game.gameName }}</td>
                    <td>{{ game.regDate.replace('T', ' ') }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr><td colspan="4">{{ $t('front.common.noGame') }}</td></tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <template v-if="playGameLog && playGameLog.length > 0">
              <template v-for="(game, index) in playGameLog" :key="game.regDate">
                <ul>
                  <li><em>#</em>{{ index + 1 }}</li>
                  <li><em>공급사</em>{{ game.vendorName }}</li>
                  <li><em>게임 명</em>{{ game.gameName }}</li>
                  <li><em>실행일시</em>{{ game.regDate.replace('T', ' ') }}</li>
                </ul>
              </template>
            </template>
            <template v-else>
              <ul>
                  <li class="nodata">{{ $t('front.common.noGame') }}</li>
              </ul>
            </template>
          </div>
        </div>
        <pagination v-if="playGameLog"
                    :pageNum="playGameLogPageInfo.page"
                    :pageSize="playGameLogPageInfo.count_per_list"
                    :totalCount="playGameLogPageInfo.tatal_list_count"
                    @goToPage="loadPlayGameLog"
        />
      </div>

      <div v-if="type == 'userBet'">
        <div class="partnerTop">
          <div><input type="text" class="input" placeholder="최소 당첨금" v-model="reqData.betLog.searchValue"/> 만원</div>
          <date-filter-Pt :startDate="reqData.betLog.startDate"
                          :endDate="reqData.betLog.endDate"
                          @update="updateBetLogDate"
                          @search="loadBetLog" />
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="msg">
              <colgroup>
                <col width="5%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
                <col width="8.6%">
              </colgroup>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('front.common.gameCompany') }}</th>
                  <th>{{ $t('front.common.gameName') }}</th>
                  <th>ROUND-ID</th>
                  <th>{{ $t('front.board.type') }}</th>
                  <th>{{ $t('front.common.beforeProcess') }}</th>
                  <th>{{ $t('front.stributor.m22') }}</th>
                  <th>{{ $t('front.stributor.m23') }}</th>
                  <th>{{ $t('front.stributor.winlose') }}</th>
                  <th>{{ $t('front.common.afterProcess') }}</th>
                  <th>{{ $t('front.common.betDate') }}</th>
                  <th>{{ $t('front.stributor.detailMore') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="betLog && betLog.length > 0">
                  <tr v-for="bet in betLog" :key="bet.betIdx">
                    <td>{{ bet.betIdx }}</td>
                    <td>{{ bet.vendorName }}</td>
                    <td>{{ bet.gameName }}</td>
                    <td>{{ bet.betId }}</td>
                    <td>
                      <template v-if="bet.type === 'wait'">
                        배팅완료
                      </template>
                      <template v-else-if="bet.type === 'win'">
                        승리
                      </template>
                      <template v-else-if="bet.type === 'lose'">
                        패배
                      </template>
                      <template v-else>
                        {{ bet.type }}
                      </template>
                    </td>
                    <td>{{ thousand(bet.bfMoney|| 0) }}</td>
                    <td>{{ thousand(bet.betAmt|| 0) }}</td>
                    <td>{{ thousand(bet.betWinAmt|| 0) }}</td>
                    <td>{{ thousand(bet.winLose|| 0) }}</td>
                    <td>{{ thousand(bet.afMoney|| 0) }}</td>
                    <td>{{ bet.regDate.replace('T', ' ') }}</td>
                    <td>
                      <a class="block" @click="betDetailOpen(bet)"><img src="@/assets/img/icon_copy.svg" style="height: 25px;" /></a>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="12">{{ $t('front.common.noBetting') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="betLog && betLog.length > 0">
                <template v-for="bet in betLog" :key="bet.betIdx">
                  <ul>
                    <li><em>#</em>{{ bet.betIdx }}</li>
                    <li><em>게임사</em>{{ bet.vendorName }}</li>
                    <li><em>게임 명</em>{{ bet.gameName }}</li>
                    <li><em>ROUND-ID</em>{{ bet.betId }}</li>
                    <li>
                      <em>구분</em>
                      <template v-if="bet.type === 'wait'">
                        배팅완료
                      </template>
                      <template v-else-if="bet.type === 'win'">
                        승리
                      </template>
                      <template v-else-if="bet.type === 'lose'">
                        패배
                      </template>
                      <template v-else>
                        {{ bet.type }}
                      </template>
                    </li>
                    <li><em>처리 전</em>{{ thousand(bet.bfMoney|| 0) }}</li>
                    <li><em>{{ $t('front.stributor.m22') }}</em>{{ thousand(bet.betAmt|| 0) }}</li>
                    <li><em>{{ $t('front.stributor.m23') }}</em>{{ thousand(bet.betWinAmt|| 0) }}</li>
                    <li><em>윈루즈</em>{{ thousand(bet.winLose|| 0) }}</li>
                    <li><em>처리 후</em>{{ thousand(bet.afMoney|| 0) }}</li>
                    <li><em>베팅 일시</em>{{ bet.regDate.replace('T', ' ') }}</li>
                    <li><em>상세보기</em><a class="block" @click="betDetailOpen(bet.betId)"><img src="@/assets/img/icon_copy.svg" style="height: 25px;" /></a></li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{ $t('front.common.noBetting') }}</li>
                </ul>
              </template>
            </div>
          </div>
        </div>
        <pagination v-if="betLog"
                    :pageNum="betLogPageInfo.page"
                    :pageSize="betLogPageInfo.count_per_list"
                    :totalCount="betLogPageInfo.tatal_list_count"
                    @goToPage="loadBetLog"
        />
      </div>
    </div>
  </div>

  <div class="modal-wrapper" v-if="isDetailOpen" @click="betDetailClose"></div>
  <div class="modal-wrap" v-if="isDetailOpen">
    <article>
      <div class="sub-title">
        <h2>베팅 상세 내역</h2>
        <button class="btn" type="button" @click="betDetailClose"><img src="@/assets/img/icon_cancel.svg" /></button>
      </div>
      <div class="page-content">
        <div class="content">
          <div v-if="betDetail && betDetail.detail && betDetail.detail.url">
            <iframe :src="betDetail.detail.url" style="width:100%; height: 80vh"></iframe>
          </div>
          <div v-else-if="Object.keys(betDetail.detail).length === 0">상세내역 없음.</div>
          <div v-else>
            <div class="resultWrap">
              <h4>베팅정보</h4>
              <table style="text-align: center; margin-top: 20px;">
                <tr>
                  <th>UID</th>
                  <th>베팅대상</th>
                  <th>게임결과</th>
                  <th>{{ $t('front.board.betMoney') }}</th>
                  <th>당첨금액</th>
                  <th>베팅시간</th>
                </tr>
                <tr>
                  <td>{{betDetail.info.betId}}</td>
                  <td>{{ memId }}</td>
                  <td>
                    <template v-if="betDetail.info.type === 'wait'">
                      배팅완료
                    </template>
                    <template v-else-if="betDetail.info.type === 'win'">
                      승리
                    </template>
                    <template v-else-if="betDetail.info.type === 'lose'">
                      패배
                    </template>
                    <template v-else>
                      {{ betDetail.info.type }}
                    </template>
                  </td>
                  <td>{{ thousand(betDetail.info.betAmt) }}</td>
                  <td>{{ thousand(betDetail.info.betWinAmt) }}</td>
                  <td>{{ betDetail.info.regDate.replace('T', ' ') }}</td>
                </tr>
              </table>
            </div>
            <template v-if="dType === 'detail_4'">
              <!-- <div class="resultWrap">
                <h4>베팅 및 게임결과</h4>
                <template v-if="betDetail.detail[0].detail.gameType === 'blackjack'">
                  <div class="result-box-wrap">
                    <template v-for="([seatName, seatData], index) in Object.entries(betDetail.detail[0].detail.result.seats)" :key="index">
                      <div class="result-box">
                        <p class="score">{{ seatName }} <span class="scoreN">{{ seatData.score }}</span></p>
                        <div class="result_c">
                          <template v-for="bscard in seatData.cards" :key="bscard">
                            <span :class="['cards', 'c' + bscard]"></span>
                          </template>
                        </div>
                      </div>
                    </template>
                    <div class="result-box">
                      <p class="score">Dealer <span class="scoreN">{{ betDetail.detail[0].detail.result.dealer.score }}</span></p>
                      <div class="result_c">
                        <template v-for="bdcard in betDetail.detail[0].detail.result.dealer.cards" :key="bdcard">
                          <span :class="['cards', 'c' + bdcard]"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="betDetail.detail[0].detail.gameType === 'powerscalableblackjack' || betDetail.detail[0].detail.gameType === 'lightningscalablebj'">
                  <div class="result-box-wrap">
                    <template v-for="hand in betDetail.detail[0].detail.participants[0].hands" :key="hand">
                      <div class="result-box">
                        <p class="score">User <span class="scoreN">{{ hand.score }}</span></p>
                        <div class="result_c">
                          <template v-for="ucard in betDetail.detail[0].detail.participants[0].hands.hand1.cards" :key="ucard">
                            <span :class="['cards', 'c' + ucard]"></span>
                          </template>
                        </div>
                      </div>
                    </template>
                    <div class="result-box" v-if="betDetail.detail[0].detail.result.dealerHand">
                      <p class="score">Dealer <span class="scoreN">{{ betDetail.detail[0].detail.result.dealerHand.score }}</span></p>
                      <div class="result_c">
                        <template v-for="dcard in betDetail.detail[0].detail.result.dealerHand.cards" :key="dcard">
                          <span :class="['cards', 'c' + dcard]"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="betDetail.detail[0].detail.gameType === 'baccarat'">
                  <div class="result-box-wrap2">
                    <div>
                      <p class="score scoreL">Player score <span class="scoreN">{{ betDetail.detail[0].detail.result.player.score }}</span></p>
                      <div class="result_c">
                        <template v-for="prcard in betDetail.detail[0].detail.result.player.cards" :key="prcard">
                          <span :class="['cards', 'c' + prcard]"></span>
                        </template>
                      </div>
                    </div>
                    <div>
                      <p class="score"><span class="scoreN">{{ betDetail.detail[0].detail.result.banker.score }}</span> Banker score</p>
                      <div class="result_c">
                        <template v-for="bkcard in betDetail.detail[0].detail.result.banker.cards" :key="bkcard">
                          <span :class="['cards', 'c' + bkcard]"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                </template>
              </div> -->
              <!-- <table>
                <tr>
                  <th>회원정보</th>
                  <td>{{ memId }}</td>
                </tr>
                <tr>
                  <th>게임사</th>
                  <td>{{ betDetail.detail[0].detail.gameProvider }}</td>
                </tr>
                <tr>
                  <th>딜러명</th>
                  <td>{{ betDetail.detail[0].detail.dealer.name }}</td>
                </tr>
                <tr>
                  <th>테이블명</th>
                  <td>{{ betDetail.detail[0].detail.table.name }}</td>
                </tr>
                <tr>
                  <th>게임ID, 플레이어ID</th>
                  <td>{{ betDetail.detail[0].detail.id }}</td>
                </tr>
                <tr>
                  <th>시작시간</th>
                  <td>{{ betDetail.detail[0].detail.startedAt }} ( UTC +9)</td>
                </tr>
                <tr>
                  <th>마감시간</th>
                  <td>{{ betDetail.detail[0].detail.settledAt }} ( UTC +9)</td>
                </tr>
                <tr>
                  <th>상태</th>
                  <td>
                    <template v-if="betDetail.detail[0].detail.status === 'Resolved'">
                      완료
                    </template>
                    <template v-else>
                      {{ betDetail.detail[0].detail.status }}
                    </template>
                  </td>
                </tr>
              </table> -->
            </template>
            <template v-else-if="dType === 'detail_5'">
              <!-- <pre>{{ betDetail.detail.detail.detail.data }}</pre> -->
              <div class="resultWrap">
                <h4>베팅 및 게임결과</h4>
                <template v-if="betDetail.detail.detail.detail.data.gameType === 'topcard'">
                  <div class="result-box-wrap">
                    <div class="result-box">
                      <p class="score">Aspot score <span class="scoreN">{{ betDetail.detail.detail.detail.data.result.aSpot.score }}</span></p>
                      <div class="result_c">
                        <span :class="['cards', 'c' + betDetail.detail.detail.detail.data.result.aSpot.card]"></span>
                      </div>
                    </div>
                    <div class="result-box">
                      <p class="score">Bspot score <span class="scoreN">{{ betDetail.detail.detail.detail.data.result.bSpot.score }}</span></p>
                      <div class="result_c">
                        <span :class="['cards', 'c' + betDetail.detail.detail.detail.data.result.bSpot.card]"></span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="betDetail.detail.detail.detail.data.gameType === 'dragontiger'">
                  <div class="result-box-wrap">
                    <div class="result-box">
                      <p class="score">Tiger score <span class="scoreN">{{ betDetail.detail.detail.detail.data.result.tiger.score }}</span></p>
                      <div class="result_c">
                        <span :class="['cards', 'c' + betDetail.detail.detail.detail.data.result.tiger.card]"></span>
                      </div>
                    </div>
                    <div class="result-box">
                      <p class="score">Dragon score <span class="scoreN">{{ betDetail.detail.detail.detail.data.result.dragon.score }}</span></p>
                      <div class="result_c">
                        <span :class="['cards', 'c' + betDetail.detail.detail.detail.data.result.dragon.card]"></span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="betDetail.detail.detail.detail.data.gameType === 'rng-blackjack' || betDetail.detail.detail.detail.data.gameType === 'blackjack'">
                  <div class="result-box-wrap2">
                    <div>
                      <template v-for="([seatName, seatData], index) in Object.entries(betDetail.detail.detail.detail.data.result.seats)" v-bind:key="index">
                        <p class="score scoreL">{{ seatName }} <span class="scoreN">{{ seatData.score }}</span></p>
                        <div class="result_c">
                          <template v-for="bscard in seatData.cards" :key="bscard">
                            <span :class="['cards', 'c' + bscard]"></span>
                          </template>
                        </div>
                      </template>
                    </div>
                    <div>
                      <p class="score"><span class="scoreN">{{ betDetail.detail.detail.detail.data.result.dealer.score }}</span> Dealer</p>
                      <div class="result_c">
                        <template v-for="bkcard in betDetail.detail.detail.detail.data.result.dealer.cards" :key="bkcard">
                          <span :class="['cards', 'c' + bkcard]"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="betDetail.detail.detail.detail.data.gameType === 'rng-baccarat' || betDetail.detail.detail.detail.data.gameType === 'baccarat'">
                  <div class="result-box-wrap2">
                    <div>
                      <p class="score scoreL">Player score <span class="scoreN">{{ betDetail.detail.detail.detail.data.result.player.score }}</span></p>
                      <div class="result_c">
                        <template v-for="prcard in betDetail.detail.detail.detail.data.result.player.cards" :key="prcard">
                          <span :class="['cards', 'c' + prcard]"></span>
                        </template>
                      </div>
                    </div>
                    <div>
                      <p class="score"><span class="scoreN">{{ betDetail.detail.detail.detail.data.result.banker.score }}</span> Banker score</p>
                      <div class="result_c">
                        <template v-for="bkcard in betDetail.detail.detail.detail.data.result.banker.cards" :key="bkcard">
                          <span :class="['cards', 'c' + bkcard]"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                </template>
              </div>
              <table>
                <tr>
                  <th>회원정보</th>
                  <td>{{ memId }}</td>
                </tr>
                <tr>
                  <th>게임사</th>
                  <td>{{ betDetail.detail.detail.detail.data.gameProvider }}</td>
                </tr>
                <tr>
                  <th>게임ID, 플레이어ID</th>
                  <td>{{ betDetail.detail.detail.detail.data.id }}</td>
                </tr>
                <tr>
                  <th>시작시간</th>
                  <td>{{ betDetail.detail.detail.detail.data.settledAt }} ( UTC +9)</td>
                </tr>
                <tr>
                  <th>마감시간</th>
                  <td>{{ betDetail.detail.detail.detail.data.startedAt }} ( UTC +9)</td>
                </tr>
                <tr>
                  <th>상태</th>
                  <td>
                    <template v-if="betDetail.detail.detail.detail.data.status === 'Resolved'">
                      완료
                    </template>
                    <template v-else>
                      {{ betDetail.detail.detail.detail.data.status }}
                    </template>
                  </td>
                </tr>
              </table>
            </template>
            <template v-else>
              <div class="resultWrap">
                <h4>베팅 및 게임결과</h4>
                <template v-if="betDetail.detail[0].detail.gameType === 'blackjack'">
                  <div class="result-box-wrap">
                    <template v-for="([seatName, seatData], index) in Object.entries(betDetail.detail[0].detail.result.seats)" :key="index">
                      <div class="result-box">
                        <p class="score">{{ seatName }} <span class="scoreN">{{ seatData.score }}</span></p>
                        <div class="result_c">
                          <template v-for="bscard in seatData.cards" :key="bscard">
                            <span :class="['cards', 'c' + bscard]"></span>
                          </template>
                        </div>
                      </div>
                    </template>
                    <div class="result-box">
                      <p class="score">Dealer <span class="scoreN">{{ betDetail.detail[0].detail.result.dealer.score }}</span></p>
                      <div class="result_c">
                        <template v-for="bdcard in betDetail.detail[0].detail.result.dealer.cards" :key="bdcard">
                          <span :class="['cards', 'c' + bdcard]"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="betDetail.detail[0].detail.gameType === 'powerscalableblackjack' || betDetail.detail[0].detail.gameType === 'lightningscalablebj'">
                  <div class="result-box-wrap">
                    <template v-for="hand in betDetail.detail[0].detail.participants[0].hands" :key="hand">
                      <div class="result-box">
                        <p class="score">User <span class="scoreN">{{ hand.score }}</span></p>
                        <div class="result_c">
                          <template v-for="ucard in betDetail.detail[0].detail.participants[0].hands.hand1.cards" :key="ucard">
                            <span :class="['cards', 'c' + ucard]"></span>
                          </template>
                        </div>
                      </div>
                    </template>
                    <div class="result-box" v-if="betDetail.detail[0].detail.result.dealerHand">
                      <p class="score">Dealer <span class="scoreN">{{ betDetail.detail[0].detail.result.dealerHand.score }}</span></p>
                      <div class="result_c">
                        <template v-for="dcard in betDetail.detail[0].detail.result.dealerHand.cards" :key="dcard">
                          <span :class="['cards', 'c' + dcard]"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="betDetail.detail[0].detail.gameType === 'baccarat'">
                  <div class="result-box-wrap2">
                    <div>
                      <p class="score scoreL">Player score <span class="scoreN">{{ betDetail.detail[0].detail.result.player.score }}</span></p>
                      <div class="result_c">
                        <template v-for="prcard in betDetail.detail[0].detail.result.player.cards" :key="prcard">
                          <span :class="['cards', 'c' + prcard]"></span>
                        </template>
                      </div>
                    </div>
                    <div>
                      <p class="score"><span class="scoreN">{{ betDetail.detail[0].detail.result.banker.score }}</span> Banker score</p>
                      <div class="result_c">
                        <template v-for="bkcard in betDetail.detail[0].detail.result.banker.cards" :key="bkcard">
                          <span :class="['cards', 'c' + bkcard]"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <!-- {{ betDetail.detail[0].detail.result }} -->
                </template>
              </div>
              <table>
                <tr>
                  <th>회원정보</th>
                  <td>{{ memId }}</td>
                </tr>
                <tr>
                  <th>게임사</th>
                  <td>{{ betDetail.detail[0].detail.gameProvider }}</td>
                </tr>
                <tr>
                  <th>딜러명</th>
                  <td>{{ betDetail.detail[0].detail.dealer.name }}</td>
                </tr>
                <tr>
                  <th>테이블명</th>
                  <td>{{ betDetail.detail[0].detail.table.name }}</td>
                </tr>
                <tr>
                  <th>게임ID, 플레이어ID</th>
                  <td>{{ betDetail.detail[0].detail.id }}</td>
                </tr>
                <tr>
                  <th>시작시간</th>
                  <td>{{ betDetail.detail[0].detail.startedAt }} ( UTC +9)</td>
                </tr>
                <tr>
                  <th>마감시간</th>
                  <td>{{ betDetail.detail[0].detail.settledAt }} ( UTC +9)</td>
                </tr>
                <tr>
                  <th>상태</th>
                  <td>
                    <template v-if="betDetail.detail[0].detail.status === 'Resolved'">
                      완료
                    </template>
                    <template v-else>
                      {{ betDetail.detail[0].detail.status }}
                    </template>
                  </td>
                </tr>
              </table>
            </template>
          </div>
        </div>
      </div>
      <!-- <pre>
      {{betDetailStr}}
      </pre> -->
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DateFilterPt from '@/components/ui/DateFilterPt'
import Pagination from '@/components/ui/Pagination.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { CASH_TYPE_LIST } from '@/libs/constants'
import {
  partnerDetailCashLogV2,
  partnerDetailLoginV2,
  partnerDetailMsgV2,
  partnerDetailMultipleRateV2,
  partnerDetailRateV2,
  partnerDetailRollingLogV2,
  partnerDetailV2,
  savePartnerDetailMultipleRateV2,
  savePartnerDetailRateV2,
  savePartnerDetailV2
} from '@/api/v2/partner'
import { getUserBetListV2, getUserPlayListV2 } from '@/api/v2/user'
import { getBettingDetailV3 } from '@/api/v2/betting'
export default {
  name: 'userDetail',
  components: {
    DateFilterPt,
    Pagination
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    isLoginYn () {
      return this.partnerInfo.loginYn === 'Y'
    }
  },
  watch: {
    'reqData.cashLog.cashType' () {
      this.loadCashLog()
    }
  },
  data () {
    return {
      type: 'partnerInfo',
      isDetailOpen: false,
      betDetail: null,
      betDetailStr: '',
      dType: null,
      openMsg: true,
      rateDetail: false,
      memId: null,
      partnerInfo: null,
      memRollingInfo: null,
      multipleRateInfo: null,
      loginLog: null,
      msgLog: null,
      msgLogPageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      cashLog: null,
      cashLogPageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      rollingLog: null,
      rollingLogPageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      playGameLog: null,
      playGameLogPageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      betLog: null,
      betLogPageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 10
      },
      reqData: {
        cashLog: {
          cashType: '',
          startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
          endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
        },
        playGameLog: {
          startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
          endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
        },
        betLog: {
          searchValue: '',
          startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
          endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
        }
      }
    }
  },
  async created () {
    this.memId = this.$route.query.id
    this.loadDetail()
    this.loadRate()
    this.loadLoginLog()
    this.loadMsgLog()
    this.loadCashLog()
    this.loadPlayGameLog()
    this.loadRollingLog()
  },
  methods: {
    CASH_TYPE_LIST (type) {
      return CASH_TYPE_LIST[type]
    },
    thousand,
    betDetailOpen (bet) {
      getBettingDetailV3({ betId: bet.betId }).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.betDetail = {
            detail: result.data.detail ? JSON.parse(result.data.detail) : {},
            info: bet
          }
          this.dType = this.betDetail.detail.dType

          this.betDetailStr = JSON.stringify(this.betDetail, undefined, 2)
        }

        console.log(res)
      })
      this.isDetailOpen = true
    },
    betDetailClose () {
      this.isDetailOpen = false
      console.log('닫기')
    },
    updateBetLogDate (value) {
      this.reqData.betLog.startDate = value.startDate
      this.reqData.betLog.endDate = value.endDate
    },
    loadBetLog (page) {
      if (page && typeof page === 'number') {
        this.betLogPageInfo.page = page
      } else {
        this.betLogPageInfo.page = 1
      }
      this.betLog = null
      const params = {
        memId: this.memId,
        ...this.reqData.betLog,
        page: this.betLogPageInfo.page
      }

      getUserBetListV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailRollingLogV2 : ', params, result)
        if (result.resultCode === '0') {
          this.betLog = result.data.list
          if (result.data.pageInfo) {
            this.betLogPageInfo = result.data.pageInfo
          }
        }
      })
    },
    updatePlayGameLogDate (value) {
      this.reqData.playGameLog.startDate = value.startDate
      this.reqData.playGameLog.endDate = value.endDate
    },
    loadPlayGameLog (page) {
      if (page && typeof page === 'number') {
        this.playGameLogPageInfo.page = page
      } else {
        this.playGameLogPageInfo.page = 1
      }
      this.playGameLog = null
      const params = {
        memId: this.memId,
        ...this.reqData.playGameLog,
        page: this.playGameLogPageInfo.page
      }

      getUserPlayListV2(params).then(res => {
        const result = res.data
        console.log('getUserPlayListV2 : ', result)
        if (result.resultCode === '0') {
          this.playGameLog = result.data.list
          if (result.data.pageInfo) {
            this.playGameLogPageInfo = result.data.pageInfo
          }
        }
      })
    },
    updateRollingLogDate (value) {
      this.reqData.rollingLog.startDate = value.startDate
      this.reqData.rollingLog.endDate = value.endDate
    },
    loadRollingLog () {
      this.rollingLog = null
      const params = {
        memId: this.memId,
        ...this.reqData.rollingLog
      }

      partnerDetailRollingLogV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailRollingLogV2 : ', result)
        if (result.resultCode === '0') {
          this.rollingLog = result.data.rollingLog
        }
      })
    },
    updateCashLogDate (value) {
      this.reqData.cashLog.startDate = value.startDate
      this.reqData.cashLog.endDate = value.endDate
    },
    loadCashLog (page) {
      if (page && typeof page === 'number') {
        this.cashLogPageInfo.page = page
      } else {
        this.cashLogPageInfo.page = 1
      }

      this.cashLog = null
      const params = {
        memId: this.memId,
        ...this.reqData.cashLog
      }

      partnerDetailCashLogV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailCashLogV2 : ', result)
        if (result.resultCode === '0') {
          this.cashLog = result.data.cashLog
          if (result.data.pageInfo) {
            this.cashLogPageInfo = result.data.pageInfo
          }
        }
      })
    },
    loadMsgLog (page) {
      if (page && typeof page === 'number') {
        this.msgLogPageInfo.page = page
      } else {
        this.msgLogPageInfo.page = 1
      }
      const params = {
        memId: this.memId
      }

      partnerDetailMsgV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailMsgV2 : ', result)
        if (result.resultCode === '0') {
          this.msgLog = result.data.msgLog
          if (result.data.pageInfo) {
            this.msgLogPageInfo = result.data.pageInfo
          }
        }
      })
    },
    loadLoginLog () {
      const params = {
        memId: this.memId
      }

      partnerDetailLoginV2(params).then(res => {
        const result = res.data
        console.log('partnerDetailLoginV2 : ', result)
        if (result.resultCode === '0') {
          this.loginLog = result.data.loginLog
        }
      })
    },
    onSaveMultipleRate () {
      if (confirm('저장하시겠습니까?')) {
        const params = {
          siteId: this.partnerInfo.siteId,
          memId: this.partnerInfo.memId,
          vendorCode: this.multipleRateInfo.vendorCode,
          rateList: []
        }

        const rateList = []

        this.multipleRateInfo.rateList.forEach(item => {
          const data = {
            rateType: item.rateType,
            pointRate: item.pointRate.toString(),
            loseRate: item.loseRate.toString()
          }
          rateList.push(data)
        })

        params.rateList = rateList

        console.log(params)

        savePartnerDetailMultipleRateV2(params).then(res => {
          const result = res.data

          if (result.resultCode === '0') {
            alert('정상적으로 저장되었습니다.')
          } else {
            alert('다시 시도해주세요. ', result.resultMessage)
          }
        })
      }
    },
    onSaveRate () {
      if (confirm('저장하시겠습니까?')) {
        const params = {
          siteId: this.partnerInfo.siteId,
          memId: this.partnerInfo.memId,
          rateList: []
        }

        const rateList = []

        this.memRollingInfo.forEach(item => {
          const data = {
            category: item.category,
            cateCode: item.cateCode,
            pointRate: item.pointRate.toString(),
            loseRate: item.loseRate.toString()
          }
          rateList.push(data)
        })

        params.rateList = rateList

        console.log(params)

        savePartnerDetailRateV2(params).then(res => {
          const result = res.data

          if (result.resultCode === '0') {
            alert('정상적으로 저장되었습니다.')
          } else {
            alert('다시 시도해주세요. ', result.resultMessage)
          }
        })
      }
    },
    onChangeRate (value, max, min, item, type) {
      if (value > max) {
        alert('최대보다 큽니다.')
        item[type + 'Rate'] = max
      }
      if (value < min) {
        alert('최소보다 작습니다.')
        item[type + 'Rate'] = min
      }
    },
    onChange (type) {
      this.partnerInfo[type] = this.partnerInfo[type] === 'Y' ? 'N' : 'Y'
    },
    onOpenRateDetail (vendorCode) {
      const params = {
        memId: this.partnerInfo.memId,
        vendorCode: vendorCode
      }

      partnerDetailMultipleRateV2(params).then(res => {
        const result = res.data

        if (result.resultCode === '0') {
          console.log(result.data)
          this.multipleRateInfo = result.data.multipleRateInfo
        }
      })
    },
    onChangeType (type) {
      this.type = type
    },
    loadRate () {
      partnerDetailRateV2({ memId: this.memId }).then(res => {
        console.log('partnerDetailRateV2 : ', res)
        const result = res.data

        if (result.resultCode === '0') {
          console.log(result.data)
          this.memRollingInfo = result.data.memRollingInfo
        }
      })
    },
    saveDetail () {
      if (confirm('저장하시겠습니까?')) {
        const param = {
          memId: this.partnerInfo.memId,
          memNick: this.partnerInfo.memNick,
          loginYn: this.partnerInfo.loginYn
        }

        savePartnerDetailV2(param).then(res => {
          const result = res.data

          if (result.resultCode === '0') {
            alert('정상적으로 저장되었습니다.')
          } else {
            alert('다시 시도해주세요. ', result.resultMessage)
          }
        })
      }
    },
    loadDetail () {
      partnerDetailV2({ memId: this.memId }).then(res => {
        console.log('partnerDetailV2 : ', res)
        const result = res.data

        if (result.resultCode === '0') {
          console.log(result.data)
          this.partnerInfo = result.data.partnerInfo
        }
      })
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped>
.modal-wrapper {
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #0000006b;
  /*display: none;*/
  font-size: 12px;
  display: flex;
}
.modal-wrapper {
  display: flex;
}
.modal-wrap {
  padding: 0;
  width: 100%;
  height: 80%;
  overflow: auto;
  max-width: 1040px;
  min-width: 550px;
  z-index: 101;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0px;
  background: #fff;
  border-color: #fff;
}
.page-contents-wrap {
  padding: 0;
  background: #353535;
}
.sub-title {
  color: #121212;
  font-size: 14px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 2px solid transparent;
}
.sub-title .btn {
  position: absolute;
  right: 15px;
  background: no-repeat;
  font-size: 22px;
  width: auto;
  height: auto;
  cursor: pointer;
}
.sub-title .btn img {vertical-align: middle;}
.page-content {
  padding: 27px 20px 25px;
  color: #121212;
  overflow: auto;
}
.page-content .content table {
  border: 1px solid #c9c9c9;
  width: 100%;
}
.page-content .content th, .page-content .content td {
  border: 1px solid #c9c9c9;
  padding: 15px;
  font-size: 13px;
}
.page-content .content p {
  font-size: 14px;
  text-align: left;
  margin: 10px 0;
}
.page-content .content p span {
  margin-left: 20px;
  display: block;
  border-radius: 18px;
  padding: 1px 12px;
}
.page-content .content p span.normal {
  background: #0f84ee;
}
.page-content .content p span.black {
  background: #808080;
}
.page-content .content p span.stop {
  background: #e1534e;
}
.page-content .content .setting {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
  text-align: center;
}
.page-content .content .setting em {
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
}
.page-content .content .btnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.page-content .content .btnWrap a {
  width: 168px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.page-content .content .btnWrap a.btn {
  border: 1px solid #a4a4a4;
  box-sizing: border-box;
  background: #545454;
}
.page-content .content .input {
  border: 1px solid #353535;
  font-size: 14px;
  width: 356px;
  height: 30px;
  margin-top: 20px;
}
.page-content .content .input::placeholder,
.page-content .content textarea::placeholder {
  color: #a4a4a4;
}
.page-content .content textarea {
  resize: none;
  width: 480px;
  height: 112px;
  padding: 10px 12px;
  font-size: 14px;
}
.modal-wrap .sub-title {
  border-color: #127c90;
}
.modal-wrap .sub-title .btn {
  color: #127c90;
  border: 0;
}
.modal-wrap .btnWrap a {
  background: #e1534e;
}
.resultWrap {
  margin-bottom: 20px;
}
.score {
  display: flex; gap: 5px; align-items: center;
}
.scoreL {
  justify-content: end;
}
.scoreN{
  padding: 3px 5px !important; border-radius: 4px !important; border: 1px solid #000; margin-left:0 !important;font-weight: bold;
}
.betBtn:hover {text-decoration: underline;font-weight: bold;}
.result_c {display: flex; align-items: center; gap:5px; }
.result_c img.cardV{width: 52px;}
.result_c img.cardH{height: 52px;}

.pagenamPT .pagename2 {margin-bottom: 20px;font-size: 20px;font-weight: 900;}
.partnerTop {display: flex;align-items: center;justify-content: end;margin-bottom: 17px;}
.start {justify-content: flex-start !important;}
.input {height: 42px !important;padding: 12px 19px !important;border: solid 1px #cfcfcf !important;background-color: #fff;font-size: 15px !important;margin-bottom: 18px;}
.topInfo {display: flex;align-items: center;gap: 10px;}
.topInfo li {width: 300px;height: 60px;border-radius: 7px;border: solid 1px #e0e0e0;box-sizing: border-box;padding-left: 22px;background-color: #fff;display: flex;flex-direction: column;justify-content: center;gap: 8px;font-size: 15px;color: #3b3b3b;}
.topInfo li.bgA {border-left: 7px solid #5bc0de;}
.topInfo li.bgB {border-left: 7px solid #f0ad4e;}
.topInfo li.bgC {border-left: 7px solid #d9534f;}
.topInfo li span {font-family: 'roboto';font-weight: 800;letter-spacing: -0.75px;}

.tab-wrap {display: flex;justify-content: flex-start;border-bottom: 4px solid #bdbfc4;box-sizing: border-box;margin-top: 30px;}
.tab-item:first-child {width: 124px;margin-left: 0;z-index: 8;}
.tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 134px;
  height: 33px;
  color: #979797;
  border-radius: 0 10px 0 0;
  font-size: 13px;
  user-select: none;
  cursor: pointer;
  position: relative;
  margin-left: -10px;
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.tab-item:nth-child(2) {z-index: 7;}
.tab-item:nth-child(3) {z-index: 6;}
.tab-item:nth-child(4) {z-index: 5;}
.tab-item:nth-child(5) {z-index: 4;}
.tab-item:nth-child(6) {z-index: 3;}
.tab-item:nth-child(7) {z-index: 2;}
.tab-item:nth-child(8) {z-index: 1;}
.tab-item:last-child {z-index: 0;}
.tab-item:hover:not(.active), .tab-item.active {background-color: #bdbfc4;color: #fff;}

.tab-content {box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);border: solid 1px #d3d4d7;background-color: #fff;padding: 12px 10px;}
.tab-content table.info {width: 545px;}
.tab-content table {font-size: 12px;color: #585963;width: 100%;border: solid 1px #d8d8d8;background-color: #fff;}
.tab-content table thead {
    width: 100%;
    box-sizing: border-box;
}
.tab-content table th {
    padding: 5px 14px;
    text-align: center;
    box-sizing: border-box;
    height: 30px;
    background-color: #f3f4f9;
    border: solid 1px #d8d8d8;
    vertical-align: middle;
}
.tab-content table td {
    padding: 0 14px;
    height: 30px;
    border: solid 1px #c4c4c4;
    vertical-align: middle;
}
tr.total {background-color: #fcffaf !important;}
tr.total td {border: 0 !important;}
.fx-center {display: flex;align-items: center;justify-content: center;gap: 10px;}
.tTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tTitle span {display: flex;align-items: center;font-size: 12px;color: #3d3d3d;font-weight: 900;}
.tTitle em {color: #876a97;margin: 0 10px;}
.tTitle i {color: #ff7e00;}
.btn-wrap {display: flex;margin: 176px 0 18px;}
.btn-save {
  height: 22px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px #000;
  background-color: #252930;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-save.block {background-color: #d91b1b;border: 0;}
.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  padding: 0px 17px;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #6457b8;color: #fff;font-size: 14px;cursor: pointer;}

.rateDetail {
  min-width: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  z-index: 10;
}
.rateDetail .close {margin-left: auto;}
.rateInput {display: flex;align-items: center;justify-content: center;gap: 10px;}
.rateInput input {width: 50px;height: 22px;box-sizing: border-box;min-width: 50px !important;padding: 5px !important;font-size: 12px;}
.rateInput button {
  display: inline-block;
  padding: 4px 6px;
  color: #fff;
  font-size: 11px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-radius: 2px;
  border: solid 1px #000;
  box-sizing: border-box;
  background: #6f7172;
}
.rateInput .min i {color: #a4bbfd;}
.rateInput .max i {color: #ffa06e;}
.rateInput button.save {font-size: 15px;padding: 5px 12px;}

.tab-content table.log {width: 715px;text-align: center;font-weight: 800;}
.tab-content table.log th,
.tab-content table.log td {height: 45px;}
.tab-content table.log tr:nth-child(even) {background-color: #f3f4f9;}

.tab-content table.msg {width: 100%;text-align: center;font-weight: 800;}
.tab-content table.msg th,
.tab-content table.msg td {height: 45px;}
.tab-content table.msg tr:nth-child(even) {background-color: #f3f4f9;}
.tab-content table.msg tr.open {background-color: #fff;}
.msg .open .pre {padding: 15px 89px;text-align: left;line-height: 1.5em;}

.fx-center select {padding: 0 5px !important;}

input[type="text"], select {
  padding: 0 5px;
  height: 20px;
  border-radius: 2px;
  border: solid 1px #898989;
  background-color: #fff;
  font-size: 12px;
  min-width: auto !important;
}
input[type="checkbox"].set-switch {
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  width: 35px;
  height: 15px !important;
  border-radius: 3em !important;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1) inset;
  border: solid 1px #b0b0b0;
  box-sizing: border-box;
  background-color: #eee;
  transition: background-color 0.15s ease-in-out;
  position: relative;
  max-width: 40px;
  min-width: auto !important;
  padding: 0 !important;
}
.set-switch:after {
  position: absolute;
  content: "";
  left: 0;
  width: 13px;
  height: 13px;
  background: #fff;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 2px 1px 5px 0px rgb(0 0 0 / 41%);
}
input[type="checkbox"].set-switch:checked {
  background: #15e41c;
}
input[type="checkbox"].set-switch:checked:after {
  left: calc(100% - 13px);
}
.strTablePC {display: block;}
.strTableM {display: none;}
.strTablePTM {width: 100%;font-size: 10px;}

.strTablePTM ul {margin-bottom: 5px;border-top: solid 1px #bdbfc4;border-bottom: solid 1px #bdbfc4;background: #FBFCFD;display: flex;flex-wrap: wrap;box-sizing: border-box;}
.strTablePTM li {width: 50%;display: flex;align-items: center;height: 35px;background: #FBFCFD;border-bottom: solid 1px #E9EAEF;box-sizing: border-box;}
.strTablePTM em {width: 70px;display: flex;align-items: center;justify-content: center;height: 35px;background: #EEF0F5;}
.strTablePTM li > div {padding: 0 5px;box-sizing: border-box;width: calc(100% - 70px);}
.strTablePTM li input {width: calc(100% - 75px);}
.nodata {width: 100% !important;font-size: 12px;justify-content: center;}
.nodata em {background: none;width: auto;margin: 0 5px 0 0;}
.nodata .tTitle {justify-content: normal;}
.nodata .btn-save {margin-left: auto;}
.txt {white-space: pre-wrap;line-height: 1.5em;width: calc(100% - 75px);}
@media (max-width: 1000px) {
  .pagenamPT .pagename2 {margin: 25px 0 15px;font-size: 20px;}
  .strTablescr {width: 100%;}
  .strTablescr > div {white-space: nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align: center;}
  .strTablePC {display: none;}
  .strTableM {display: block;}
  .topInfo {gap: 5px;}
  .topInfo li {width: 100%;padding-left: 10px;}
  .tab-content table {width: 100% !important;}
}
@media (max-width: 800px) {
  .tab-wrap {flex-wrap: wrap;}
  .tab-item {width: 33.33% !important;margin-left: 0;border-radius: 0;font-size: 11px;}
  .partnerTop {flex-direction: column;}
}
@media (max-width: 500px) {
  .partnerTop select {margin: 0 auto 10px 0;}
  .topInfo {flex-wrap: wrap;}
  .topInfo li {width: calc(33.33% - 4px);}
}
.result-box-wrap {margin-top: 20px; display: flex; justify-content: center; gap: 20px; align-items: center;flex-direction: column;}
.result-box-wrap2 {margin-top: 20px; display: flex; justify-content: center; gap: 20px; align-items: center;}
.result-box {display: flex;align-items: center;justify-content: space-between;width: 75%;}
.scards {width: 100%;height: 35px; min-width: 22px;display: flex;background-size: contain;background-repeat: no-repeat;background-position: center;}
.cards {width: 100%;height: 83px; min-width: 60px;display: flex;background-size: contain;background-repeat: no-repeat;background-position: center;}

.c2C {background-image: url(~@/assets/img/card/c2.png);}
.c3C {background-image: url(~@/assets/img/card/c3.png);}
.c4C {background-image: url(~@/assets/img/card/c4.png);}
.c5C {background-image: url(~@/assets/img/card/c5.png);}
.c6C {background-image: url(~@/assets/img/card/c6.png);}
.c7C {background-image: url(~@/assets/img/card/c7.png);}
.c8C {background-image: url(~@/assets/img/card/c8.png);}
.c9C {background-image: url(~@/assets/img/card/c9.png);}
.cTC {background-image: url(~@/assets/img/card/c10.png);}
.cAC {background-image: url(~@/assets/img/card/ca.png);}
.cJC {background-image: url(~@/assets/img/card/cj.png);}
.cKC {background-image: url(~@/assets/img/card/ck.png);}
.cQC {background-image: url(~@/assets/img/card/cq.png);}

.c2D {background-image: url(~@/assets/img/card/d2.png);}
.c3D {background-image: url(~@/assets/img/card/d3.png);}
.c4D {background-image: url(~@/assets/img/card/d4.png);}
.c5D {background-image: url(~@/assets/img/card/d5.png);}
.c6D {background-image: url(~@/assets/img/card/d6.png);}
.c7D {background-image: url(~@/assets/img/card/d7.png);}
.c8D {background-image: url(~@/assets/img/card/d8.png);}
.c9D {background-image: url(~@/assets/img/card/d9.png);}
.cTD {background-image: url(~@/assets/img/card/d10.png);}
.cAD {background-image: url(~@/assets/img/card/da.png);}
.cJD {background-image: url(~@/assets/img/card/dj.png);}
.cKD {background-image: url(~@/assets/img/card/dk.png);}
.cQD {background-image: url(~@/assets/img/card/dq.png);}

.c2S {background-image: url(~@/assets/img/card/s2.png);}
.c3S {background-image: url(~@/assets/img/card/s3.png);}
.c4S {background-image: url(~@/assets/img/card/s4.png);}
.c5S {background-image: url(~@/assets/img/card/s5.png);}
.c6S {background-image: url(~@/assets/img/card/s6.png);}
.c7S {background-image: url(~@/assets/img/card/s7.png);}
.c8S {background-image: url(~@/assets/img/card/s8.png);}
.c9S {background-image: url(~@/assets/img/card/s9.png);}
.cTS {background-image: url(~@/assets/img/card/s10.png);}
.cAS {background-image: url(~@/assets/img/card/sa.png);}
.cJS {background-image: url(~@/assets/img/card/sj.png);}
.cKS {background-image: url(~@/assets/img/card/sk.png);}
.cQS {background-image: url(~@/assets/img/card/sq.png);}

.c2H {background-image: url(~@/assets/img/card/h2.png);}
.c3H {background-image: url(~@/assets/img/card/h3.png);}
.c4H {background-image: url(~@/assets/img/card/h4.png);}
.c5H {background-image: url(~@/assets/img/card/h5.png);}
.c6H {background-image: url(~@/assets/img/card/h6.png);}
.c7H {background-image: url(~@/assets/img/card/h7.png);}
.c8H {background-image: url(~@/assets/img/card/h8.png);}
.c9H {background-image: url(~@/assets/img/card/h9.png);}
.cTH {background-image: url(~@/assets/img/card/h10.png);}
.cAH {background-image: url(~@/assets/img/card/ha.png);}
.cJH {background-image: url(~@/assets/img/card/hj.png);}
.cKH {background-image: url(~@/assets/img/card/hk.png);}
.cQH {background-image: url(~@/assets/img/card/hq.png);}
</style>
